import React, { useState } from "react";

const AccordionItem = ({
  showDescription,
  ariaExpanded,
  fontWeightBold,
  item,
  index,
  onClick,
  toggleState
}) => {


  
  return (
    <div className="faq__question" key={item.id}>
    <dt onClick={onClick}>
      <button
        aria-expanded={toggleState[index]}
        aria-controls={`faq${index + 1}_desc`}
        data-qa="faq__question-button"
        className={toggleState[index]?`faq__question-button ${fontWeightBold}`:`faq__question-button`}
        
        
      >
        {item.question}
      </button>
    </dt>
    <dd>
      <p
        id={`faq${index + 1}_desc`}
        data-qa="faq__desc"
        className={toggleState[index]?`faq__desc ${showDescription}`:`faq__desc`}
        dangerouslySetInnerHTML={{__html: item.answer}}
      >
    
      </p>
    </dd>
  </div>
  )}

  
;

export default AccordionItem;
