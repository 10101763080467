import Home from './containers/Home'
import 'font-awesome/css/font-awesome.min.css';
import { GlobalState } from './context/GlobalState';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Subscription from './components/Popups';
import Checkout from './components/Checkout';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { analytics } from './api/GTMTag';
import Policy from './containers/Policy';
import TermUse from './containers/TermUse';
import { GTM_ID } from './api/Variables';
import Thankyou from './containers/ThankYou';

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
    }, []);
  return (
    <GlobalState>
    <div className="App">
    <BrowserRouter>
      <Routes>
      <Route index element={<Home />} />
      <Route path="privacy-policy" element={<Policy />} />
      <Route path="term-use" element={<TermUse />} />
      <Route path="thank-you" element={<Thankyou />} />


        <Route path="planoffer/:plan_name" element={<Checkout />} />

        
      </Routes>
    </BrowserRouter>

    
    </div>
    </GlobalState>
  );
}

export default App;
