import React, { useState,useRef } from 'react'
import './plans.css'
import { calculatePercentage, compareData, filterPlans, removeFromString } from '../../api/Functions';
import { Link } from 'react-router-dom';
import { pushGTMTAG } from '../../api/GTMTag';

const Index = ({expanPlan,handleClick, showSubsPopup, compareList, setCompareList, plans, maxPlanList}) => {

    const annulaRef = useRef(null);
    const monthlyRef = useRef(null);

    
    const swtchPlans = (e, tab) => {
        if (tab === "Annual") {
            annulaRef.current.classList.add("active");
            monthlyRef.current.classList.remove("active");
            var filterData = filterPlans(plans.Result, 'Annual');
            setCompareList(compareData(filterData, maxPlanList));
        }
        if (tab === "Monthly") {
            annulaRef.current.classList.remove("active");
            monthlyRef.current.classList.add("active");
            var filterData = filterPlans(plans.Result, 'Monthly');
            setCompareList(compareData(filterData, maxPlanList));
        }
      };
  
  return (
    <div className={expanPlan?'plansWap show':'plansWap hide'} >
      <div className='headRow'>
        <div className='rowLeft'>
            <span className=''>Our<br /> Plans</span>
        </div>
        <div className='rowRight'>
        {
                compareList.planNames?.map((key) => {

                    return (<div key={key} className='planClms'>{removeFromString(['WATCHO'], key)}</div>)
                })
            }
           
        </div>
      </div>
     
      {
        compareList.planKeys?.map((data) => {
          
            return (
                <div className='contRow' key={data}>
                <div className='rowLeft'>
                    <img className='ottLogo' src={compareList.ottList[data].app.AppIconUrl} alt={data} />
                </div>
                <div className='rowRight'>
                {
                    compareList.planNames?.map((key) => {
                   
                        return ( <div className='checkmark'>
                        
                            {compareList.ottList[data].compare[key].SubscriptionPlanID ? <span><i className='fa fa-check'></i></span>  : ''}
                            
                                           
                    </div>)
                    })
                }
                
                            
                </div>
              </div>
            )
        })
    }
      
      


      <div className='contRow dashed'>
        <div className='rowLeft'>
            <span className=''>Apps Worth</span>
        </div>
        <div className='rowRight'>   
        {
                compareList.planNames?.map((key) => {

                    return (<div className='realPrice' key={key}>
                            <span>&#8377; {compareList.planPrices[key]?.SubscriptionDisplayPriceWithTax}</span>
                            </div>
                            )
                })
            }         
          
           
        </div>
      </div>
      <div className='contRow dashed'>
        <div className='rowLeft switch-tab'>
            <span className=''>You Pay</span>
            <div className='stabs'>
                <button value='Annual' ref={annulaRef} className='switch-btn active' onClick={(e)=>swtchPlans(e,'Annual')}>Yearly</button>
                <button value='Monthly' ref={monthlyRef} className='switch-btn' onClick={(e)=>swtchPlans(e,'Monthly')}>Monthly</button>
            </div>
        </div>
        <div className='rowRight'> 
        {
                compareList.planNames?.map((key) => {

                    return (<div className='reducedPrice' key={key}>
                    <span>&#8377; {compareList.planPrices[key]?.SubscriptionPriceWithTax} <i className='info'onClick={() => handleClick(compareList.planPrices[key])} >i</i></span>
                    
                   </div>
                            )
                })
            }            
           
           
        </div>
      </div>

      <div className='contRow dashed border-0'>
        <div className='rowLeft'>
            <span className=''>You Save</span>
        </div>
        <div className='rowRight prcent'> 
        {
                compareList.planNames?.map((key) => {

                    return (
                        <div key={key}>{calculatePercentage(compareList.planPrices[key]?.SubscriptionDisplayPriceWithTax, compareList.planPrices[key]?.SubscriptionPriceWithTax)}%</div>
                       
                    )
                })
            } 
         
        </div>
      </div>
      
      <div className='contRow dashed border-0'>
        <div className='rowLeft'>
            
        </div>
        <div className='rowRight'>  
        {
                compareList.planNames?.map((key) => {

                    return (
                        <>
                        <Link key={key} onClick={() => pushGTMTAG(compareList.planPrices[key], 'buy_now')} to={`/planoffer/${(removeFromString(['WATCHO'], key)).trim().toLowerCase()}`} state={compareList.planPrices[key]} className='site-btn-default' >Subscribe</Link>
                        </>
                    )
                })
            }           
           
        </div>
      </div>

    </div>
  )
}

export default Index
