import React, { useState } from "react";
import AccordionItem from "./AccordionItem";

const Accordion = ({ questionsAnswers }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [toggleState, setToggleState] = useState({});

  const handleClick = (e,index) => {
    e.currentTarget.classList.toggle('selected');
    setActiveIndex(index);
    if(e.currentTarget.className == 'selected'){
      setToggleState({[index]: true});
    }
    else {
      setToggleState({[index]: false});
    }
    
  };

  const renderedQuestionsAnswers = questionsAnswers.map((item, index) => {
    const showDescription = index === activeIndex ? "show-description" : "";
    const fontWeightBold = index === activeIndex ? "font-weight-bold" : "";
    const ariaExpanded = index === activeIndex ? "true" : "false";
    return (
      <AccordionItem
        showDescription={showDescription}
        fontWeightBold={fontWeightBold}
        ariaExpanded={ariaExpanded}
        toggleState={toggleState}
        item={item}
        index={index}
        onClick={(e) => {
          handleClick(e,index)
        }}
      key={item.id}

      />
    );
  });

  return (
    <div className="faqlist" key="1">
      <dl className="faq__list">{renderedQuestionsAnswers}</dl>
    </div>
  );
};

export default Accordion;
